<template>
  <router-view />
</template>

<script>
var warningTimeoutID = undefined;
var logoutTimeoutID = undefined;
const events = ["click", "mousemove", "mousedown", "keydown"];

const body = document.querySelector("body");

export default {

  watch: {
    "$store.getters.getloggedIn": {
      deep: true,
      handler() {
        console.log("here");
        this.autoLogout();
      },
    },
  },

  mounted() {
    this.autoLogout();
  },

  methods: {
    autoLogout() {
      window.addEventListener("DOMContentLoaded", () => {
        if (localStorage.getItem('admin_token')) {
          console.log("auto logout start");
          warningTimeoutID = setTimeout(this.callTimeoutFunc, 300000);
          events.forEach((event) => {
            window.addEventListener(event, this.eventHandler);
          });
        } else {
          clearTimeout(warningTimeoutID);
          clearTimeout(logoutTimeoutID);
          events.forEach((event) => {
            window.removeEventListener(event, this.eventHandler);
          })
        }
      });
    },

    callTimeoutFunc() {
      const div = document.createElement("div");
      div.className = "warning";
      div.textContent =
        "you have been inactive for sometime and now you are being logged out";
      body.prepend(div);
      logoutTimeoutID = setTimeout(() => {
        this.$store.commit('setloggedIn', false);
        localStorage.removeItem('admin');
        localStorage.removeItem('admin_token');
        location.replace('/login');
      }, 5000);
    },

    eventHandler() {
      if (logoutTimeoutID) {
        clearTimeout(logoutTimeoutID);
        if (body.children[0].classList.contains("warning"))
          body.removeChild(body.firstElementChild);
      }
      clearTimeout(warningTimeoutID);
      warningTimeoutID = setTimeout(this.callTimeoutFunc, 300000);
    }

  }
}


</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: 'Poppins';
}

body>p {
  width: 80vw;
  margin: auto;
  line-height: 2rem;
}

.warning {
  background-color: crimson;
  padding: 15px 50px;
  text-align: center;
  font-size: large;
  font-weight: bold;
  text-transform: capitalize;
  font-stretch: expanded;
  margin-top: 84px;
  border-radius: 2px;
  line-height: 2rem;
}

@font-face {
  font-family: 'Poppins';
  src: url('./assets/fonts/Poppins/Poppins-Thin.ttf');
  font-weight: 300;
}

@font-face {
  font-family: 'Poppins';
  src: url('./assets/fonts/Poppins/Poppins-Regular.ttf');
  font-weight: 400;
}

@font-face {
  font-family: 'Poppins';
  src: url('./assets/fonts/Poppins/Poppins-Medium.ttf');
  font-weight: 500;
}

@font-face {
  font-family: 'Poppins';
  src: url('./assets/fonts/Poppins/Poppins-SemiBold.ttf');
  font-weight: 600;
}

@font-face {
  font-family: 'Poppins';
  src: url('./assets/fonts/Poppins/Poppins-Bold.ttf');
  font-weight: 700;
}

@font-face {
  font-family: 'Poppins';
  src: url('./assets/fonts/Poppins/Poppins-ExtraBold.ttf');
  font-weight: 800;
}

/*****************skeltor loader css */
.vue-skeletor {
  position: relative;
  overflow: hidden;
  background-color: rgba(#000, 0.12);
}

.vue-skeletor:not(.vue-skeletor--shimmerless):after {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transform: translateX(-100%);
  background-image: linear-gradient(90deg,
      rgba(255, 255, 255, 0),
      rgba(255, 255, 255, .3),
      rgba(37, 22, 22, 0));
  animation: shimmer 1.5s infinite;
  content: '';
}


.vue-skeletor--rect,
.vue-skeletor--circle {
  display: block;
}

.vue-skeletor--circle {
  border-radius: 50%;
}

.vue-skeletor--pill,
.vue-skeletor--text {
  border-radius: 9999px;
}

.vue-skeletor--text {
  line-height: 1;
  display: inline-block;
  width: 100%;
  height: inherit;
  vertical-align: middle;
  top: -1px;
}


@keyframes shimmer {
  100% {
    transform: translateX(100%);
  }
}

.apexcharts-legend{
    top:20px !important;
}
.bonus_pagination .pagination{
  justify-content: left;
}

/*****************skeltor loader css */



@media all and (min-width:320px) and (max-width:767px) {
 .pagination_overflow{
  overflow-x:scroll;
 }
 tspan{
    font-size: 9px;
}
}
</style>
