import { createStore } from 'vuex'
import createPersistedState from "vuex-persistedstate";
export default createStore({
  plugins: [createPersistedState()],
  state: {
    loggedIn:false,
    opened:0,
  },
  getters: {
    getloggedIn(state){
      return state.loggedIn;
    },
    getopened(state){
      return state.opened;
    },
    getfullscreen(state){
      return state.full;
    },
  },
  mutations: {
    setopened(state, payload){
      return state.opened = payload;
    },
    setloggedIn(state,payload){
      
       state.loggedIn = payload;
       
       console.log(state.loggedIn);
       return;
    },
    setfullscreen(state, payload){
      return state.full = payload;
    },
  },
  actions: {
  },
  modules: {
  }
})
