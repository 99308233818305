import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import 'bootstrap'
import 'bootstrap/dist/css/bootstrap.min.css'
//css
import './assets/css/app.min.css';
import './assets/css/custom.css';
import './assets/css/icons.min.css';
import './assets/css/bootstrap.min.css';
import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
import Pagination from 'v-pagination-3';
import { Skeletor } from "vue-skeletor";

import vue3PhotoPreview from 'vue3-photo-preview';
import 'vue3-photo-preview/dist/index.css';
import ApiClass from './api/api'

const mixins = {
    methods: {
        getDate: function (date, time) {
            let d = new Date(date).toLocaleDateString("en-US")
            if (time) {
                return d + " " + new Date(date).toLocaleTimeString("en-US", { hour12: false });
            }
            return d;

        },
        toastMessage: function (icon, message) {
            this.$swal({
                title: message,
                position: "top-end",
                icon,
                toast: true,
                timer: "3000",
                showConfirmButton: false,
            });
        },
        success: function (message) {
            this.$swal({
                title: message,
                position: "top-end",
                icon: "success",
                toast: true,
                timer: "3000",
                showConfirmButton: false,
            });
        },
        failed: function (message) {
            this.$swal({
                title: message,
                position: "top-end",
                icon: "error",
                toast: true,
                timer: "3000",
                showConfirmButton: false,
            });
        },
        saveFile: async function (fillename,all,urlORdata){
            let apiData={};
            if(all){
                let base_url = urlORdata+'?all=true' ;
                apiData = await ApiClass.getNodeRequest(base_url); 
                // console.log({api_data: apiData.data.data});
            }
            let data = all?apiData.data.data.excel:urlORdata
            if(data){
            // console.log(urlORdata);
            var mediaType="data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,";
            var a = document.createElement('a');
            a.href = mediaType+data;
            a.download = fillename+'.xlsx';
            a.click()
            }else{this.failed("Some Error Occured");}
            }
    },
};

createApp(App).use(store).use(VueSweetalert2).use(router).use(vue3PhotoPreview).component('pagination', Pagination).component(Skeletor.name, Skeletor).mixin(mixins).mount('#app')
